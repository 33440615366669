import { ProductCategoryType } from '../product';

export enum WeekFaza {
  Germination = -1,
  Vegetation = 0,
  Flowering = 1,
  Harvest = 2,
}

export enum WeekFazaLetter {
  Germination = 'g',
  Vegetation = 'v',
  Flowering = 'f',
  Harvest = 'h',
}


export enum DiaryTypeMethod {
  Lst = 2,
  Hst = 3,
  Sog = 4,
  Scrog = 5,
  Topping = 6,
  Fiming = 7,
  MainLining = 8,
  Defoliation = 9,
  Manifolding = 10,
  TwelveTwelve = 11,
  Transplantation = 12,
}


export enum DiaryTypeMedium {
  Soil = 1,
  Perlite = 2,
  Vermiculite = 3,
  ExpandedClay = 4,
  CocoCoir = 5,
  MineralWool = 6,
  Other = 7,
}



export enum DiaryTypeRoom {
  Indoor = 1,
  Outdoor = 2,
  Greenhouse = 3
}

export enum DiaryTypeWatering {
  Manual = 1,
  Drip = 2,
  Hydroponic = 3,
  Aeroponic = 4
}


export const DiaryTypeRoomImage: Record<DiaryTypeRoom, string> = {
  [DiaryTypeRoom.Indoor]: '/images/setup_diary/setup_diary_icons-01.svg',
  [DiaryTypeRoom.Outdoor]: '/images/setup_diary/setup_diary_icons-02.svg',
  [DiaryTypeRoom.Greenhouse]: '/images/setup_diary/setup_diary_icons-19.svg'
};

 
export const DiaryTypeRoomInfo: Record<DiaryTypeRoom, { tr: string, img: string, desc: string }> = {
  [DiaryTypeRoom.Indoor]: {
    tr: 'universal_type_room_indoor',
    img: '/images/setup_diary/setup_diary_icons-01.svg',
    desc: 'Indoor – Suitable for interior spaces, providing a controlled and comfortable environment for various activities.',
  },
  [DiaryTypeRoom.Outdoor]: {
    tr: 'universal_type_room_outdoor',
    img: '/images/setup_diary/setup_diary_icons-02.svg',
    desc: 'Outdoor – Ideal for exterior areas, designed for open-air use and interactions with nature.',
  },
  [DiaryTypeRoom.Greenhouse]: {
    tr: 'universal_type_room_greenhouse',
    img: '/images/setup_diary/setup_diary_icons-19.svg',
    desc: 'Greenhouse – Perfect for cultivating plants in a protected, climate-controlled environment to optimize growth.',
  },
};

 

export const DiaryTypeWateringInfo: Record<DiaryTypeWatering, { tr: string, img: string, desc: string }> = {
  [DiaryTypeWatering.Manual]: {
    tr: 'universal_type_watering_manual',
    img: '/images/setup_diary/watering_manual.svg',
    desc: 'Manual Watering – Traditional method involving manually delivering water to plants, allowing precise control over moisture levels.',
  },
  [DiaryTypeWatering.Drip]: {
    tr: 'universal_type_watering_drip',
    img: '/images/setup_diary/watering_drip.svg',
    desc: 'Drip Watering – An irrigation system that delivers water directly to plant roots through a network of tubes and emitters, ensuring efficient and consistent moisture distribution.',
  },
  [DiaryTypeWatering.Hydroponic]: {
    tr: 'universal_type_watering_hydroponics',
    img: '/images/setup_diary/watering_hydroponics.svg',
    desc: 'Hydroponics – Soilless cultivation method where plants grow in a nutrient-rich water solution, enabling faster growth and higher yields.',
  },
  [DiaryTypeWatering.Aeroponic]: {
    tr: 'universal_type_watering_aeroponics',
    img: '/images/setup_diary/watering_aeroponics.svg',
    desc: 'Aeroponics – Advanced irrigation technique where plant roots are suspended in the air and misted with a nutrient solution, promoting rapid growth and efficient nutrient absorption.',
  },
};


export const DiaryProductCategoriesMinInfo = [
  ProductCategoryType.Lamp,
  ProductCategoryType.Tent,
  ProductCategoryType.GrowBox,
  ProductCategoryType.VentilationFan,
  ProductCategoryType.AirFilter,
  ProductCategoryType.AirConditioner,
  ProductCategoryType.DripSystem,
  ProductCategoryType.HydroponicSystem,
  ProductCategoryType.Controller,
  ProductCategoryType.CO2Generator,
  // ProductCategoryType.Substrate,
]





export const TabsDiary = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'nutrients',
    name: 'Nutrients',
  }, 
  {
    id: 'comments',
    name: 'Comments',
  },
]

export const TabsDiaryHar = [ 
  {
    id: 'setup',
    name: 'Setup',
  }, 
  {
    id: 'reviews',
    name: 'Reviews',
  },  
  {
    id: 'comments',
    name: 'Comments',
  },
]





export enum ReviewEffectPositiveEnum {
  Creative = 1,
  Energetic = 2,
  Euphoric = 3,
  Giggly = 4,
  Happy = 5,
  Hungry = 6,
  Relaxed = 7,
  Sleepy = 8,
  Talkative = 9,
  Uplifted = 10,
}


export const ReviewEffectPositiveEnumInfo: Record<ReviewEffectPositiveEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectPositiveEnum.Creative]:
  {
    tr: 'strain_view_tastes_effects_creative',
    img: '/images/effects/positive/creative.svg',
    key: 'creative',
  },
  [ReviewEffectPositiveEnum.Energetic]:
  {
    tr: 'strain_view_tastes_effects_energetic',
    img: '/images/effects/positive/energetic.svg',
    key: 'energetic',
  },
  [ReviewEffectPositiveEnum.Euphoric]:
  {
    tr: 'strain_view_tastes_effects_euphoric',
    img: '/images/effects/positive/euphoric.svg',
    key: 'euphoric',
  },
  [ReviewEffectPositiveEnum.Giggly]:
  {
    tr: 'strain_view_tastes_effects_giggly',
    img: '/images/effects/positive/giggly.svg',
    key: 'giggly',
  },
  [ReviewEffectPositiveEnum.Happy]:
  {
    tr: 'strain_view_tastes_effects_happy',
    img: '/images/effects/positive/happy.svg',
    key: 'happy',
  },
  [ReviewEffectPositiveEnum.Hungry]:
  {
    tr: 'strain_view_tastes_effects_hungry',
    img: '/images/effects/positive/hungry.svg',
    key: 'hungry',
  },
  [ReviewEffectPositiveEnum.Relaxed]:
  {
    tr: 'strain_view_tastes_effects_relaxed',
    img: '/images/effects/positive/relaxed.svg',
    key: 'relaxed',
  },
  [ReviewEffectPositiveEnum.Sleepy]:
  {
    tr: 'strain_view_tastes_effects_sleepy',
    img: '/images/effects/positive/sleepy.svg',
    key: 'sleepy',
  },
  [ReviewEffectPositiveEnum.Talkative]:
  {
    tr: 'strain_view_tastes_effects_talkative',
    img: '/images/effects/positive/talkative.svg',
    key: 'talkative',
  },
  [ReviewEffectPositiveEnum.Uplifted]:
  {
    tr: 'strain_view_tastes_effects_uplifted',
    img: '/images/effects/positive/uplifted.svg',
    key: 'uplifted',
  },
}



export enum ReviewEffectNegativeEnum {
  DryEyes = 11,
  DryMouth = 12,
  Insomnia = 13,
  Paranoia = 14,
}

export const ReviewEffectNegativeEnumInfo: Record<ReviewEffectNegativeEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectNegativeEnum.DryEyes]:
  {
    tr: 'strain_view_tastes_effects_dry_eyes',
    img: '/images/effects/negative/dry-eyes.svg',
    key: 'dry_eyes',
  },
  [ReviewEffectNegativeEnum.DryMouth]:
  {
    tr: 'strain_view_tastes_effects_dry_mouth',
    img: '/images/effects/negative/dry-mouth.svg',
    key: 'dry_mouth',
  },
  [ReviewEffectNegativeEnum.Insomnia]:
  {
    tr: 'strain_view_tastes_effects_insomnia',
    img: '/images/effects/negative/insomnia.svg',
    key: 'insomnia',
  },
  [ReviewEffectNegativeEnum.Paranoia]:
  {
    tr: 'strain_view_tastes_effects_paranoia',
    img: '/images/effects/negative/paranoia.svg',
    key: 'paranoia',
  },
}

export enum ReviewEffectMedicalEnum {
  Stress = 15,
  Pain = 16,
  Insomnia = 17,
  Anxiety = 18,
  Depression = 19,
}

export const ReviewEffectMedicalEnumInfo: Record<ReviewEffectMedicalEnum, { tr: string, img: string, key: string }> = {
  [ReviewEffectMedicalEnum.Stress]:
  {
    tr: 'strain_view_tastes_effects_stress',
    img: '/images/effects/medical/stress.svg',
    key: 'stress',
  },
  [ReviewEffectMedicalEnum.Pain]:
  {
    tr: 'strain_view_tastes_effects_pain',
    img: '/images/effects/medical/pain.svg',
    key: 'pain',
  },
  [ReviewEffectMedicalEnum.Insomnia]:
  {
    tr: 'strain_view_tastes_effects_insomnia',
    img: '/images/effects/medical/insomnia.svg',
    key: 'insomnia',
  },
  [ReviewEffectMedicalEnum.Anxiety]:
  {
    tr: 'strain_view_tastes_effects_anxiety',
    img: '/images/effects/medical/anxiety.svg',
    key: 'anxiety',
  },
  [ReviewEffectMedicalEnum.Depression]:
  {
    tr: 'strain_view_tastes_effects_depression',
    img: '/images/effects/medical/depression.svg',
    key: 'depression',
  },
}

export enum ReviewTasteEnum {
  Berries = 1,
  Cheese = 2,
  Citrus = 3,
  Diesel = 4,
  Earthy = 5,
  Flowery = 6,
  Mint = 7,
  Nutty = 8,
  Sweet = 9,
  Tropical = 10,
  Caramel = 12,
  Chocolate = 13,
  Cream = 14,
  Fruity = 15,
  Herbs = 16,
  Pine = 17,
  Woody = 11,
  Pungent = 18,
  Sour = 19,
}

export const ReviewTasteEnumInfo: Record<ReviewTasteEnum, { tr: string, img: string, key: string }> = {
  [ReviewTasteEnum.Berries]: 
  {
    tr: 'strain_view_tastes_effects_berries',
    img: '/images/tastes/berries.svg',
    key: 'berries',
  },
  [ReviewTasteEnum.Cheese]:
  {
    tr: 'strain_view_tastes_effects_cheese',
    img: '/images/tastes/cheese.svg',
    key: 'cheese',
  },
  [ReviewTasteEnum.Citrus]:
  {
    tr: 'strain_view_tastes_effects_citrus',
    img: '/images/tastes/citrus.svg',
    key: 'citrus',
  },
  [ReviewTasteEnum.Diesel]:
  {
    tr: 'strain_view_tastes_effects_diesel',
    img: '/images/tastes/diesel.svg',
    key: 'diesel',
  },
  [ReviewTasteEnum.Earthy]:
  {
    tr: 'strain_view_tastes_effects_earthy',
    img: '/images/tastes/earthy.svg',
    key: 'earthy',
  },
  [ReviewTasteEnum.Flowery]:
  {
    tr: 'strain_view_tastes_effects_flowery',
    img: '/images/tastes/flowery.svg',
    key: 'flowery',
  },
  [ReviewTasteEnum.Mint]:
  {
    tr: 'strain_view_tastes_effects_mint',
    img: '/images/tastes/mint.svg',
    key: 'mint',
  },
  [ReviewTasteEnum.Nutty]:
  {
    tr: 'strain_view_tastes_effects_nutty',
    img: '/images/tastes/nutty.svg',
    key: 'nutty',
  },
  [ReviewTasteEnum.Sweet]:
  {
    tr: 'strain_view_tastes_effects_sweet',
    img: '/images/tastes/sweet.svg',
    key: 'sweet',
  },
  [ReviewTasteEnum.Tropical]:
  {
    tr: 'strain_view_tastes_effects_tropical',
    img: '/images/tastes/tropical.svg',
    key: 'tropical',
  },
  [ReviewTasteEnum.Caramel]:
  {
    tr: 'strain_view_tastes_effects_caramel',
    img: '/images/tastes/caramel.svg',
    key: 'caramel',
  },
  [ReviewTasteEnum.Chocolate]:
  {
    tr: 'strain_view_tastes_effects_chocolate',
    img: '/images/tastes/chocolate.svg',
    key: 'chocolate',
  },
  [ReviewTasteEnum.Cream]:
  {
    tr: 'strain_view_tastes_effects_cream',
    img: '/images/tastes/cream.svg',
    key: 'cream',
  },
  [ReviewTasteEnum.Fruity]:
  {
    tr: 'strain_view_tastes_effects_fruity',
    img: '/images/tastes/fruity.svg',
    key: 'fruity',
  },
  [ReviewTasteEnum.Herbs]:
  {
    tr: 'strain_view_tastes_effects_herbs',
    img: '/images/tastes/herbs.svg',
    key: 'herbs',
  },
  [ReviewTasteEnum.Pine]:
  {
    tr: 'strain_view_tastes_effects_pine',
    img: '/images/tastes/pine.svg',
    key: 'pine',
  },
  [ReviewTasteEnum.Woody]:
  {
    tr: 'strain_view_tastes_effects_woody',
    img: '/images/tastes/woody.svg',
    key: 'woody',
  },
  [ReviewTasteEnum.Pungent]:
  {
    tr: 'strain_view_tastes_effects_pungent',
    img: '/images/tastes/pungent.svg',
    key: 'pungent',
  },
  [ReviewTasteEnum.Sour]:
  {
    tr: 'strain_view_tastes_effects_sour',
    img: '/images/tastes/sour.svg',
    key: 'sour',
  },
}


export enum WeekHarvestReviewSeedPropsGrowingEnum {
  Easy = 1,
  Medium = 2,
  Hard = 3,
}
 

export enum WeekHarvestReviewSeedPropsResistanceEnum {
  Weak = 1,
  Neurtal = 2,
  Strong = 3,
}


 




// localization keys



export const WeekFazaNameTr: Record<WeekFaza, string> = {
  [WeekFaza.Germination]: 'universal_type_faza_ger',
  [WeekFaza.Vegetation]: 'universal_type_faza_veg',
  [WeekFaza.Flowering]: 'universal_type_faza_flo',
  [WeekFaza.Harvest]: 'universal_type_faza_har',
};

export const WeekFazaNameShortTr: Record<WeekFaza, string> = {
  [WeekFaza.Germination]: 'universal_type_faza_short_ger',
  [WeekFaza.Vegetation]: 'universal_type_faza_short_veg',
  [WeekFaza.Flowering]: 'universal_type_faza_short_flo',
  [WeekFaza.Harvest]: 'universal_type_faza_short_har',
};


export const DiaryTypeMethodTr: Record<DiaryTypeMethod, string> = {
  [DiaryTypeMethod.Lst]: 'universal_type_method_lst',
  [DiaryTypeMethod.Hst]: 'universal_type_method_hst',
  [DiaryTypeMethod.Sog]: 'universal_type_method_sog',
  [DiaryTypeMethod.Scrog]: 'universal_type_method_scrog',
  [DiaryTypeMethod.Topping]: 'universal_type_method_topping',
  [DiaryTypeMethod.Fiming]: 'universal_type_method_fiming',
  [DiaryTypeMethod.MainLining]: 'universal_type_method_main_lining',
  [DiaryTypeMethod.Defoliation]: 'universal_type_method_defoliation',
  [DiaryTypeMethod.Manifolding]: 'universal_type_method_manifolding',
  [DiaryTypeMethod.TwelveTwelve]: 'universal_type_method_12_12',
  [DiaryTypeMethod.Transplantation]: 'universal_type_method_transplantation',
};

export const DiaryTypeMethodShortTr: Record<DiaryTypeMethod, string> = {
  [DiaryTypeMethod.Lst]: 'universal_type_method_short_lst',
  [DiaryTypeMethod.Hst]: 'universal_type_method_short_hst',
  [DiaryTypeMethod.Sog]: 'universal_type_method_short_sog',
  [DiaryTypeMethod.Scrog]: 'universal_type_method_short_scrog',
  [DiaryTypeMethod.Topping]: 'universal_type_method_short_topping',
  [DiaryTypeMethod.Fiming]: 'universal_type_method_short_fiming',
  [DiaryTypeMethod.MainLining]: 'universal_type_method_short_main_lining',
  [DiaryTypeMethod.Defoliation]: 'universal_type_method_short_defoliation',
  [DiaryTypeMethod.Manifolding]: 'universal_type_method_short_manifolding',
  [DiaryTypeMethod.TwelveTwelve]: 'universal_type_method_short_12_12',
  [DiaryTypeMethod.Transplantation]: 'universal_type_method_short_transplantation',
};
 

 

export const DiaryTypeMediumTr: Record<DiaryTypeMedium, string> = {
  [DiaryTypeMedium.Soil]: 'universal_type_medium_soil',
  [DiaryTypeMedium.Perlite]: 'universal_type_medium_perlite',
  [DiaryTypeMedium.Vermiculite]: 'universal_type_medium_vermiculite',
  [DiaryTypeMedium.ExpandedClay]: 'universal_type_medium_expanded_clay',
  [DiaryTypeMedium.CocoCoir]: 'universal_type_medium_coco_coir',
  [DiaryTypeMedium.MineralWool]: 'universal_type_medium_mineral_wool',
  [DiaryTypeMedium.Other]: 'universal_type_medium_other',
};



export enum WeekPropsSmellEnum {
  NoSmell = 1,
  Weak = 2,
  Normal = 3,
  Strong = 4,
}

export const WeekPropsSmellEnumTr: Record<WeekPropsSmellEnum, string> = {
  [WeekPropsSmellEnum.NoSmell]: 'set_nosmell',
  [WeekPropsSmellEnum.Weak]: 'set_weak',
  [WeekPropsSmellEnum.Normal]: 'set_normal',
  [WeekPropsSmellEnum.Strong]: 'set_strong',
}

export const WeekPropsSmellEnumIcon: Record<WeekPropsSmellEnum, string> = {
  [WeekPropsSmellEnum.NoSmell]: 'icon-smell-no',
  [WeekPropsSmellEnum.Weak]: 'icon-smell',
  [WeekPropsSmellEnum.Normal]: 'icon-smell',
  [WeekPropsSmellEnum.Strong]: 'icon-smell',
}

export enum DiaryGrowScoreEnum {
  Frown = 1,
  Meh = 2,
  Smile = 3,
}

export const DiaryGrowScoreEnumTr: Record<DiaryGrowScoreEnum, string> = {
  [DiaryGrowScoreEnum.Frown]: 'universal_grow_score_text_frown',
  [DiaryGrowScoreEnum.Meh]: 'universal_grow_score_text_meh',
  [DiaryGrowScoreEnum.Smile]: 'universal_grow_score_text_smile',
}

export const DiaryGrowScoreIcon: Record<DiaryGrowScoreEnum, string> = {
  [DiaryGrowScoreEnum.Frown]: 'frown',
  [DiaryGrowScoreEnum.Meh]: 'meh',
  [DiaryGrowScoreEnum.Smile]: 'smile',
}


 

export const DiaryChartTypes = [
  {
    name: 'Height',
    prop: 'height',
    icon: 'icon-height',
    color: '#00FF00',
    unit: 'length',
  },
  {
    name: 'Day air temperature',
    prop: 'air_temp',
    icon: 'icon-air-temperature',
    color: '#FF00FF',
    unit: 'temperature',
  },
  {
    name: 'Air humidity',
    prop: 'air_hum',
    icon: 'icon-air-humidity',
    color: '#FFAA00',
    unit: null,
    yAxisUnit: '%',
  },
  {
    name: 'PPM',
    prop: 'ppm',
    icon: 'icon-ec',
    color: '#FF00FF',
    unit: 'ppm',
  },
  {
    name: 'PH',
    prop: 'ph',
    icon: 'icon-ph',
    color: '#FF00FF',
    unit: null
  },
  {
    name: 'CO2',
    prop: 'co2',
    icon: 'icon-co2',
    color: '#FFAA00',
    unit: 'ppm',
  },
  {
    name: 'Light schedule',
    prop: 'light',
    icon: 'icon-light-schedule',
    color: '#00FF00',
    yAxisUnit: 'Active hours',
  },
  // {
  //   name: 'Smell',
  //   prop: 'smell',
  //   icon: 'icon-smell',
  //   color: '#FF00FF',
  //   unit: null,
  // },
  {
    name: 'Solution temperature',
    prop: 'solution_temp',
    icon: 'icon-solution-temperature',
    color: '#FFAA00',
    unit: 'temperature',
  },
  {
    name: 'Night air temperature',
    prop: 'night_air_temp',
    icon: 'icon-night-air-temperature',
    color: '#00FF00',
    unit: 'temperature',
  },
  {
    name: 'Substrate temperature',
    prop: 'substrate_temp',
    icon: 'icon-substrate-temperature',
    color: '#FF00FF', 
    unit: 'temperature',
  },
  {
    name: 'Pot size',
    prop: 'pot_size',
    icon: 'icon-pot',
    color: '#FFAA00',
    unit: 'volume',
  },
  {
    name: 'Lamp distance',
    prop: 'lamp_distance',
    icon: 'icon-lamp-distance',
    color: '#00FF00',
    unit: 'length',
  }, 
  {
    name: 'Watering volume',
    icon: 'icon-watering-volume',
    color: '#00FF00',
    unit: 'volume',
  },
]